/* eslint-disable no-unused-expressions */
import React, { Component } from 'react'
import * as Yup from 'yup'
import Header from '../header/Header'
import { Typeahead } from 'react-bootstrap-typeahead'

import backIcon from '../../utils/images/icons-lelt-open-arrow.svg'
import api from '../../utils/api'
import { toast } from 'react-toastify'
import IconFooter from '../footer/IconFooter'
import Footer from '../footer/Footer'
import CopyrightFooter from '../footer/CopyrightFooter'
import { Add } from '@material-ui/icons'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import ErrorComponent from './ErrorComponent'
import VotesData from './VotesData'

export default class ElectionForm extends Component {
  constructor(props) {
    super(props)

    this.state = {
      districtOptions: [],
      vidhanSabhaOptions: [],
      // panchayatOptions: [],
      pollingStationOptions: [],
      isUpdate: this?.props?.location?.state?.isUpdate ? true : false,
    }
  }

  initialValues = {
    district: this?.props?.location?.state?.district || '',
    vidhanSabha: this?.props?.location?.state?.vidhanSabha || '',
    //panchayat: this?.props?.location?.state?.panchayat || '',
    village: this?.props?.location?.state?.meta?.village || '',
    pollingStation: this?.props?.location?.state?.pollingStation || '',
    electionType: this?.props?.location?.state?.electionType || '',
    data: this?.props?.location?.state?.isUpdate
      ? this?.props?.location?.state?.data.map((obj) => {
          return {
            ...obj,
            year: new Date(obj.year),
          }
        })
      : [
          {
            total: '',
            vote: '',
            percent: '',
            year: '',
          },
        ],
  }

  validationSchema = Yup.object({
    district: Yup.string().required('Required'),
    vidhanSabha: Yup.string().required('Required'),
    village: Yup.string(),
    pollingStation: Yup.string().required('Required'),
    electionType: Yup.string(),
    data: Yup.array().of(
      Yup.object({
        total: Yup.string(),
        percent: Yup.string(),
        vote: Yup.string(),
        year: Yup.date().required('Required'),
        id: Yup.string(),
      })
    ),
  })

  componentDidMount = () => {
    this.fetchConstituency()
  }

  electionTypeOptions = [
    {
      label: 'Lok Sabha Elections',
      value: 'Lok Sabha Elections',
    },
    {
      label: 'General Elections',
      value: 'General Elections',
    },
  ]

  fetchConstituency = async () => {
    await api
      .getConstituency({
        type: 'Zila Level',
        limit: '10000',
        offset: '0',
      })
      .then((response) => {
        if (response.data.error) {
          toast.error('No District found. Please make District first.', {
            autoClose: 1250,
            closeButton: false,
          })
        } else {
          localStorage.setItem(
            'District',
            JSON.stringify(response?.data?.data?.rows || [])
          )
          let temp = []

          const { data } = response

          data?.data?.rows.forEach((obj) => {
            temp.push({
              label: obj?.meta?.name,
              district: obj?.meta.name,
            })
          })

          this.setState({ districtOptions: [...temp] })
        }
      })
    await api
      .getConstituency({
        type: 'Vidhan Sabha',
        limit: '10000',
        offset: '0',
      })
      .then((response) => {
        if (response.data.error) {
          toast.error(
            'No Vidhan Sabha found. Please make Vidhan Sabha first.',
            {
              autoClose: 1250,
              closeButton: false,
            }
          )
        } else {
          localStorage.setItem(
            'Vidhan Sabha',
            JSON.stringify(response?.data?.data?.rows || [])
          )
          // let temp = []

          // const { data } = response

          // data?.data?.rows.forEach((obj) => {
          //   temp.push({
          //     vidhanSabha: obj?.meta?.name,
          //     district: obj?.meta.name,
          //   })
          // })

          // this.setState({ vidhanSabhaOptions: [...temp] })
        }
      })
    // await api
    //   .getConstituency({
    //     type: 'Gram Panchayat',
    //     limit: '10000',
    //     offset: '0',
    //   })
    //   .then((response) => {
    //     if (response.data.error) {
    //       toast.error('No Panchayat found. Please make Panchayat first.', {
    //         autoClose: 1250,
    //         closeButton: false,
    //       })
    //     } else {
    //       localStorage.setItem(
    //         'Panchayat',
    //         JSON.stringify(response.data.data.rows)
    //       )
    //     }
    //   })
    await api
      .getConstituency({
        type: 'Polling Station',
        limit: '10000',
        offset: '0',
      })
      .then((response) => {
        if (response.data.error) {
          toast.error(
            'No Polling Station found. Please make Polling Station first.',
            {
              autoClose: 1250,
              closeButton: false,
            }
          )
        } else {
          localStorage.setItem(
            'Polling Station',
            JSON.stringify(response.data.data.rows)
          )
        }
      })
  }

  makeVidhanSabha = (district = '', setFieldValue) => {
    if (district === '') {
      setFieldValue('pollingStation', '')
      setFieldValue('vidhanSabha', '')
      //setFieldValue('panchayat', '')

      return false
    }

    const vidhanSabha = JSON.parse(localStorage.getItem('Vidhan Sabha'))
    let temp = []
    vidhanSabha &&
      vidhanSabha.length !== 0 &&
      vidhanSabha.forEach((data) => {
        if (data?.meta?.district.toLowerCase() === district?.toLowerCase()) {
          temp.push(data.meta.name)
        }
      })
    if (temp.length === 0) {
      toast.error(
        'No Vidhan Sabha for this district. Please make Vidhan sabha.',
        {
          autoClose: 1500,
          closeButton: false,
        }
      )
    } else {
      this.setState({
        vidhanSabhaOptions: [...temp],
      })
    }
  }

  // makePanchayat = (vidhanSabha, setFieldValue) => {
  //   const Panchayat = JSON.parse(localStorage.getItem('Panchayat'))

  //   let temp = Panchayat.filter(
  //     (data) => data?.meta?.vidhanSabha === vidhanSabha
  //   )

  //   temp = temp.map((data) => ({ panchayat: data?.meta?.name }))

  //   if (temp.length === 0) {
  //     toast.error(
  //       'No Panchayat for this vidhan sabha. Please make Panchayat.',
  //       {
  //         autoClose: 1500,
  //         closeButton: false,
  //       }
  //     )
  //   } else {
  //     this.setState({
  //       panchayatOptions: [...temp],
  //     })
  //   }
  // }

  makePollingStation = (district, vidhanSabha, setFieldValue) => {
    if (
      vidhanSabha === '' ||
      //values.panchayat === '' &&
      district === ''
    ) {
      setFieldValue('pollingStation', '')

      return false
    }
    const PollingStation = JSON.parse(localStorage.getItem('Polling Station'))

    let temp = []

    PollingStation &&
      PollingStation.length !== 0 &&
      PollingStation.forEach((data) => {
        if (
          data?.meta?.district?.toLowerCase() === district.toLowerCase() &&
          data?.meta?.vidhanSabha?.toLowerCase() === vidhanSabha.toLowerCase()
        ) {
          if (data?.meta?.name) {
            temp.push({
              pollingStation: data?.meta?.name,
            })
          }
        }
      })

    if (temp.length === 0) {
      toast.error(
        'No Polling Station for this district/vidhan sabha. Please make Polling Station.',
        {
          autoClose: 1500,
          closeButton: false,
        }
      )
      this.setState({
        pollingStationOptions: [],
      })
      setFieldValue('pollingStation', '')
    } else {
      this.setState({
        pollingStationOptions: [...temp],
      })
    }
  }

  handleFormSubmit = async (values, index, setFieldValue) => {
    let body = {
      ...values,
      ...values.data[index],
      year: new Date(values.data[index].year).getFullYear().toString(),
      panchayat: 'N/A',
      meta: {
        electionType: values.electionType,
        village: values?.village,
      },
      percent: values?.data[index].percent.toString(),
    }
    delete body.data
    delete body.electionType
    delete body.village

    try {
      if (this.state.isUpdate || (body?.id && body?.id !== '')) {
        const res = await api.updateElectionData({ ...body })
        toast.success('Election data updated successfully.')
      } else {
        const res = await api.postElectionData({ ...body })
        if (res?.data?.code !== 'SUCCESS') {
          toast.error(`${res?.data?.error || 'Unable to add data.'}`)
          return false
        }
        let temp = [...values.data]
        temp[index].id = res?.data?.data?.id
        setFieldValue(`data`, [...temp])
        toast.success('Election data added successfully.')
      }
    } catch (error) {
      toast.error(
        `${
          this?.state?.isUpdate
            ? 'Unable to update election data.'
            : 'Unable to add election data.'
        }`
      )
    }
  }

  render() {
    return (
      <>
        <div className="NewClientForm">
          <Header />
          <div className="frame">
            <div className="FormOuterFrame">
              <div className="DivHeading">
                <img
                  src={backIcon}
                  alt=""
                  className="backIcon"
                  onClick={() => this.props.history.goBack()}
                ></img>
                <p className="TxtHeading">Election Data</p>
              </div>
              <div className="FormFrame">
                <Formik
                  initialValues={this.initialValues}
                  validationSchema={this.validationSchema}
                  onSubmit={async (values, formikBag) => {
                    let errors = await formikBag.validateForm()

                    if (Object.keys(errors).length !== 0) {
                      formikBag.setErrors(errors)
                      return false
                    }
                    return true
                  }}
                >
                  {({
                    setFieldValue,
                    values,
                    handleSubmit,
                    errors,
                    isValid,
                  }) => {
                    console.log('errors', errors)
                    return (
                      <Form>
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <Field name="district">
                              {({ field }) => {
                                return (
                                  <>
                                    <div className="TxtInputFrame">
                                      <p className="TxtInput">District</p>
                                      <p className="TxtStar">*</p>
                                    </div>

                                    <Typeahead
                                      id="district"
                                      name="district"
                                      labelKey="district"
                                      placeholder="District"
                                      required
                                      onChange={(district) => {
                                        setFieldValue(
                                          'district',
                                          district[0]?.district || ''
                                        )
                                        this.makeVidhanSabha(
                                          district[0]?.district || '',
                                          setFieldValue
                                        )
                                      }}
                                      options={this?.state?.districtOptions}
                                      defaultInputValue={field.value}
                                      value={field.value}
                                      style={{ marginBottom: '20px' }}
                                    />
                                  </>
                                )
                              }}
                            </Field>

                            <ErrorMessage
                              name="district"
                              component={ErrorComponent}
                            />
                          </div>
                          <div className="col-12 col-md-6">
                            <Field name="vidhanSabha">
                              {({ field }) => {
                                return (
                                  <>
                                    <div className="TxtInputFrame">
                                      <p className="TxtInput">Vidhan Sabha</p>
                                      <p className="TxtStar">*</p>
                                    </div>
                                    <Typeahead
                                      id="vidhanSabha"
                                      labelKey="vidhanSabha"
                                      placeholder="Vidhan Sabha"
                                      required
                                      onChange={(vidhanSabha) => {
                                        setFieldValue(
                                          'vidhanSabha',
                                          vidhanSabha[0] || ''
                                        )
                                        this.makePollingStation(
                                          values?.district,
                                          vidhanSabha[0],
                                          setFieldValue
                                        )
                                      }}
                                      options={this?.state?.vidhanSabhaOptions}
                                      defaultInputValue={field.value}
                                      value={field.value}
                                      style={{ marginBottom: '20px' }}
                                    />
                                  </>
                                )
                              }}
                            </Field>
                            <ErrorMessage
                              name="vidhanSabha"
                              component={ErrorComponent}
                            />
                          </div>

                          <div className="col-12 col-md-6">
                            <Field name="pollingStation">
                              {({ field }) => {
                                return (
                                  <>
                                    <div className="TxtInputFrame">
                                      <p className="TxtInput">
                                        Polling Station
                                      </p>
                                      <p className="TxtStar">*</p>
                                    </div>
                                    <Typeahead
                                      id="pollingStation"
                                      labelKey="pollingStation"
                                      placeholder="Polling Station"
                                      required
                                      onChange={(pollingStation) => {
                                        setFieldValue(
                                          'pollingStation',
                                          pollingStation[0]?.pollingStation ||
                                            ''
                                        )
                                      }}
                                      options={this.state.pollingStationOptions}
                                      defaultInputValue={field.value}
                                      style={{ marginBottom: '20px' }}
                                    />
                                  </>
                                )
                              }}
                            </Field>
                            <ErrorMessage
                              name="pollingStation"
                              component={ErrorComponent}
                            />
                          </div>

                          <div className="col-12 col-md-6">
                            <Field name={`village`}>
                              {({ field }) => {
                                return (
                                  <>
                                    <div className="TxtInputFrame">
                                      <p className="TxtInput">Village</p>
                                    </div>
                                    <input
                                      type="text"
                                      id="village"
                                      className="InputFrame"
                                      placeholder="Village"
                                      value={field.value}
                                      onChange={(e) => {
                                        setFieldValue(`village`, e.target.value)
                                      }}
                                    />
                                  </>
                                )
                              }}
                            </Field>
                            <ErrorMessage
                              name={`village`}
                              component={ErrorComponent}
                            />

                            {/* <Field name="panchayat">
                              {({ field }) => {
                                return (
                                  <>
                                    <div className="TxtInputFrame">
                                      <p className="TxtInput">Gram Panchayat</p>
                                      <p className="TxtStar">*</p>
                                    </div>
                                    <Typeahead
                                      id="panchayat"
                                      labelKey="panchayat"
                                      placeholder="Gram Panchayat"
                                      required
                                      onChange={(panchayat) => {
                                        setFieldValue(
                                          'panchayat',
                                          panchayat[0]?.panchayat || ''
                                        )
                                        this.makePollingStation(
                                          panchayat[0]?.panchayat || '',
                                          values,
                                          setFieldValue
                                        )
                                      }}
                                      options={this.state.panchayatOptions}
                                      defaultInputValue={field.value}
                                      style={{ marginBottom: '20px' }}
                                    />
                                  </>
                                )
                              }}
                            </Field>
                            <ErrorMessage
                              name="panchayat"
                              component={ErrorComponent}
                            /> */}
                          </div>

                          <div className="col-12 col-md-6">
                            <Field name="electionType">
                              {({ field }) => {
                                return (
                                  <>
                                    <div className="TxtInputFrame">
                                      <p className="TxtInput">Election Type</p>
                                    </div>
                                    <div>
                                      <Typeahead
                                        id="electionType"
                                        labelKey="label"
                                        placeholder="Election Type"
                                        onChange={(electionType) => {
                                          setFieldValue(
                                            'electionType',
                                            electionType[0]?.value || ''
                                          )
                                        }}
                                        options={this.electionTypeOptions}
                                        defaultInputValue={field.value}
                                        style={{ marginBottom: '20px' }}
                                      />
                                    </div>
                                  </>
                                )
                              }}
                            </Field>

                            <ErrorMessage
                              name="electionType"
                              component={ErrorComponent}
                            />
                          </div>
                        </div>

                        {values.data &&
                          values?.data?.map((electionData, index) => {
                            return (
                              <div key={index}>
                                <VotesData
                                  values={values}
                                  index={index}
                                  setFieldValue={setFieldValue}
                                  handleSubmit={handleSubmit}
                                  errors={errors}
                                  handleFormSubmit={this.handleFormSubmit}
                                  isValid={isValid}
                                />
                              </div>
                            )
                          })}
                        <div className="d-flex align-items-center justify-content-end">
                          <button
                            className="d-flex align-items-center pb-3 border-0 bg-transparent text-decoration-underline fs-6"
                            type="button"
                            style={{
                              width: 'fit-content',
                              cursor: 'pointer',
                              color: '#2f2d64',
                              fontFamily: 'Segoe UI',
                            }}
                            onClick={(e) => {
                              e.preventDefault()
                              setFieldValue(`data[${values?.data?.length}]`, {
                                total: '',
                                vote: '',
                                percent: '',
                                year: '',
                              })
                            }}
                          >
                            <span className="mr-2">
                              <Add
                                style={{
                                  color: '#2f2d64',
                                  fontSize: '14px',
                                }}
                              />
                            </span>
                            Add row
                          </button>
                        </div>
                      </Form>
                    )
                  }}
                </Formik>
              </div>
            </div>
            <div className="DashboardFooter mb-5">
              <IconFooter />
              <Footer />
              <CopyrightFooter />
            </div>
          </div>
        </div>
      </>
    )
  }
}
