import React from 'react'

const CopyRightFooter = () => {
  return (
    <div className="footerSection lowerFooter">
      <p className="copyRight">CopyRightText</p>
    </div>
  )
}

export default CopyRightFooter
