import { ErrorMessage, Field } from 'formik'
import React from 'react'
import DatePicker from 'react-datepicker'
import ErrorComponent from './ErrorComponent'
import { IconButton } from '@material-ui/core'
import { AddCircle, Cancel, CheckCircle } from '@material-ui/icons'
import api from '../../utils/api'
import { toast } from 'react-toastify'

const VotesData = ({
  index,
  setFieldValue,
  values,
  handleSubmit,
  errors,
  handleFormSubmit,
  isValid,
}) => {
  const makePercentage = (vote, total) => {
    if (vote === '' && total === '') {
      return ''
    }

    let votes = parseInt(vote)
    let totalVotes = parseInt(total)

    let percent = (votes / totalVotes) * 100
    setFieldValue(`data[${index}].percent`, Math.floor(percent, 2))
    //return Math.floor(percent, 2)
  }

  const deleteElectionData = (id) => {
    api
      .deleteElectionData({ id: id })
      .then((response) => {
        if (response.ok) {
          toast.success('Entry Deleted SuccessFully', {
            autoClose: 1250,
            closeButton: false,
          })
          let temp = [...values.data]
          let filterArr = temp.filter((elm, i) => i !== index)
          setFieldValue('data', [...filterArr])
        } else {
          toast.error('Something Went Wrong.', {
            autoClose: 1500,
            closeButton: false,
          })
        }
      })
      .catch((err) => {
        toast.error('Something Went Wrong. Please Refresh', {
          autoClose: false,
          closeButton: false,
        })
      })
  }

  return (
    <>
      <div className="row m-0 p-0 align-items-center" key={index}>
        <div className="col-12 col-md-8 col-lg-11">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-3">
              <Field name={`data[${index}].year`}>
                {({ field }) => {
                  return (
                    <>
                      <div className="TxtInputFrame">
                        <p className="TxtInput">Year</p>
                        <p className="TxtStar">*</p>
                      </div>
                      <DatePicker
                        dateFormat="yyyy"
                        required
                        placeholder="Select Year"
                        className="InputFrame"
                        selected={field.value}
                        name="year"
                        onChange={(date, e) => {
                          setFieldValue(`data[${index}].year`, date)
                        }}
                      />
                    </>
                  )
                }}
              </Field>
              <ErrorMessage
                name={`data[${index}].year`}
                component={ErrorComponent}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <Field name={`data[${index}].vote`}>
                {({ field }) => {
                  return (
                    <>
                      <div className="TxtInputFrame">
                        <p className="TxtInput">BJP Votes</p>
                        <p className="TxtStar">*</p>
                      </div>
                      <input
                        type="text"
                        id="vote"
                        className="InputFrame"
                        placeholder="Votes"
                        required
                        value={field.value}
                        onChange={(e) => {
                          setFieldValue(`data[${index}].vote`, e.target.value)
                          makePercentage(
                            e.target.value,

                            values?.data[index].total
                          )
                        }}
                      />
                    </>
                  )
                }}
              </Field>
              <ErrorMessage
                name={`data[${index}].vote`}
                component={ErrorComponent}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <Field name={`data[${index}].total`}>
                {({ field }) => {
                  return (
                    <>
                      <div className="TxtInputFrame">
                        <p className="TxtInput">Total Votes</p>
                        <p className="TxtStar">*</p>
                      </div>

                      <input
                        type="text"
                        id="total"
                        className="InputFrame"
                        placeholder="Total Votes"
                        required
                        value={field.value}
                        onChange={(e) => {
                          setFieldValue(`data[${index}].total`, e.target.value)
                          makePercentage(
                            values?.data[index].vote,

                            e.target.value
                          )
                        }}
                      />
                    </>
                  )
                }}
              </Field>
              <ErrorMessage
                name={`data[${index}].total`}
                component={ErrorComponent}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <div className="TxtInputFrame">
                <p className="TxtInput">%</p>
              </div>
              <input
                type="text"
                id="percent"
                name="percent"
                className="InputFrame"
                placeholder="percent"
                disabled={true}
                value={values?.data[index]?.percent || ''}
              />
            </div>
          </div>
        </div>
        <div className="col-12 col-md-10 col-lg-1 px-0">
          <div className="d-flex align-items-center justify-content-between">
            <IconButton
              disabled={values?.data.length === 1}
              onClick={() => {
                if (values.data[index]?.id && values?.data[index].id !== '') {
                  deleteElectionData(values.data[index]?.id)
                } else {
                  let temp = [...values.data]
                  let filterArr = temp.filter((elm, i) => i !== index)
                  setFieldValue('data', [...filterArr])
                }
              }}
            >
              <Cancel
                style={{
                  color: '#2f2d64',
                  fontSize: '1.5rem',
                }}
              />
            </IconButton>
            <IconButton
              onClick={() => {
                if (Object.keys(errors).length === 0 && isValid) {
                  handleFormSubmit(values, index, setFieldValue)
                } else {
                  handleSubmit(values, index)
                }
              }}
            >
              <CheckCircle
                style={{
                  color: '#2f2d64',
                  fontSize: '1.5rem',
                }}
              />
            </IconButton>
          </div>
        </div>
      </div>
    </>
  )
}

export default VotesData
