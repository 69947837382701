import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './utils/fonts/Segoe.ttf'
import './utils/fonts/SegoeBold.ttf'
import 'bootstrap/dist/css/bootstrap.min.css'
// import { Beforeunload } from 'react-beforeunload'

ReactDOM.render(
  <React.StrictMode>
    {/* <Beforeunload onBeforeunload={() => "u will lose data"}> */}
    <App />
    {/* </Beforeunload> */}
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
