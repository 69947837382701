import React, { Component, Fragment } from 'react'

import { Row, Col } from 'react-bootstrap'

import Back from '../../../utils/images/back.svg'
import warningLogo from '../../../utils/images/warningLogo.png'

import TablePagination from '@material-ui/core/TablePagination'

import trash from '../../../utils/images/trash.svg'

export default class GramPanchayat extends Component {
  constructor(props) {
    super(props)
    this.state = {
      page: 0,
      rowsPerPage: 25,
      panchayatData: [],
      count: 0,
      initialData: [],
      searchInput: this.props.searchInput,
    }
  }

  componentDidMount = () => {
    let data = JSON.parse(localStorage.getItem('Gram Panchayat'))
    this.setState({
      initialData: data?.rows || [],
      panchayatData: data?.rows,
      count: data?.count || data?.rows.length || 0,
    })
  }

  componentDidUpdate = (prevProps, prevState) => {
    // Check if the state has changed
    if (this.state.searchInput !== prevProps.searchInput) {
      this.debounce(this.filterDataAccToSearch, 1000)(prevProps?.searchInput) // Adjust the delay as needed
    } else if (
      this.state.initialData.length !== this.state.panchayatData.length &&
      prevProps.searchInput === ''
    ) {
      this.setState({
        panchayatData: this.state.initialData,
        count: this.state.initialData?.length,
      })
    }
  }

  setPage = (event, newPage) => {
    this.setState({ page: newPage })
  }

  setRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, page: 0 })
  }

  updateConstituency = (constituencyType, data) => {
    this.props.history.push({
      pathname: '/add-constituency',
      state: {
        constituencyType: constituencyType,
        data: data,
        updateConstData: true,
      },
    })
  }

  debounce = (func, delay) => {
    let timeoutId
    return function () {
      const context = this
      const args = arguments
      clearTimeout(timeoutId)
      timeoutId = setTimeout(() => {
        func.apply(context, args)
      }, delay)
    }
  }

  filterDataAccToSearch = (inputValue) => {
    const filteredData = this.state.initialData.filter((data) => {
      return (
        data?.meta?.name
          .toLowerCase()
          .indexOf(this.props.searchInput.toLowerCase()) !== -1 ||
        (data?.meta?.district &&
          (data?.meta?.district !==
            ''.toLowerCase().indexOf(this.props.searchInput.toLowerCase())) !==
            -1) ||
        (data?.meta?.vidhanSabha &&
          (data?.meta?.vidhanSabha !==
            ''.toLowerCase().indexOf(this.props.searchInput.toLowerCase())) !==
            -1)
      )
    })

    this.setState({
      panchayatData: [...filteredData],
      count: filteredData.length,
    })
  }

  render() {
    return (
      <Fragment>
        <div className="FormOuterFrame">
          <div className="requestRow">
            <Row>
              <Col md={4}>
                <p className="HeadingTxt">Gram Panchayat</p>
              </Col>

              <Col md={3}>
                <p className="HeadingTxt">District</p>
              </Col>
              <Col md={3}>
                <p className="HeadingTxt">Vidhan Sabha</p>
              </Col>
              {/* <Col md={2}>
                <p className="HeadingTxt">Polling Station</p>
              </Col> */}
              <Col md={1}></Col>
              <Col md={1}></Col>
            </Row>
          </div>
          {this.state.panchayatData?.length !== 0 ? (
            this.state.panchayatData
              .slice(
                this.state.page * this.state.rowsPerPage,
                this.state.page * this.state.rowsPerPage +
                  this.state.rowsPerPage
              )
              .map((data) => {
                return (
                  <div>
                    <div className="requestRow">
                      <Row>
                        <Col md={4} style={{ alignSelf: 'center' }}>
                          <p className="DataTxt">{data?.meta?.name || ''}</p>
                        </Col>

                        <Col md={3} style={{ alignSelf: 'center' }}>
                          <p className="DataTxt">
                            {data?.meta?.district || ''}
                          </p>
                        </Col>
                        <Col md={3} style={{ alignSelf: 'center' }}>
                          <p className="DataTxt">
                            {data?.meta?.vidhanSabha || ''}
                          </p>
                        </Col>
                        {/* <Col md={2} style={{ alignSelf: 'center' }}>
                          <p className="DataTxt">
                            {data?.meta?.pollingStation || ''}
                          </p>
                        </Col> */}

                        <Col md={1}>
                          <img
                            src={trash}
                            alt=""
                            className="Back"
                            onClick={() =>
                              this.props.deleteConstituency(data.id)
                            }
                            style={{ cursor: 'pointer' }}
                          ></img>
                        </Col>
                        <Col md={1}>
                          <img
                            src={Back}
                            alt=""
                            className="Back"
                            style={{ cursor: 'pointer' }}
                            onClick={() =>
                              this.updateConstituency(
                                this.props.constituencyType,
                                data
                              )
                            }
                          ></img>
                        </Col>
                      </Row>
                    </div>
                  </div>
                )
              })
          ) : (
            <div className="warningMsg">
              <div className="warningHead">
                <img className="warningLogo" src={warningLogo} alt=""></img>
                <p>Warning</p>
              </div>
              <p className="warningTxt">No data found</p>
            </div>
          )}
          <TablePagination
            rowsPerPageOptions={[25, 50, 100]}
            component="div"
            count={this.state?.count || 0}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onChangePage={this.setPage}
            onChangeRowsPerPage={this.setRowsPerPage}
          />
        </div>
      </Fragment>
    )
  }
}
