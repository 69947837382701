/* eslint-disable no-unused-expressions */
import React, { Component } from 'react'
import api from '../../utils/api'
import { Typeahead } from 'react-bootstrap-typeahead'
import Header from '../header/Header'
import Footer from '../footer/Footer'
import CopyrightFooter from '../footer/CopyrightFooter'
import { toast } from 'react-toastify'
import DatePicker from 'react-datepicker'
import { PieChart } from 'react-minimal-pie-chart'

export default class Report extends Component {
  constructor(props) {
    super(props)
    this.state = {
      vidhanSabha: '',
      panchayat: '',
      year: '',
      pollingStation: '',
      vidhanSabhaOptions: [],
      panchayatOptions: [],
      pollingStationOptions: [],
      isLoading: true,
      all: {},
      by: [],
      dataSet: [],
    }
  }

  componentDidMount = () => {
    this.fetchConstituency()
    this.fetchReport()
  }

  fetchReport = async () => {
    const values = {
      ...this.state,
      year:
        this.state?.year && this.state?.year !== ''
          ? new Date(this.state.year).getFullYear().toString()
          : '',
    }

    let payload = {}

    delete values.isLoading
    delete values.all

    for (const value in values) {
      if (values[value] !== '' && !Array.isArray(values[value])) {
        payload[value] = values[value]
      }
    }

    try {
      const res = await api.getElectionReport({ ...payload })
      const { data } = res
      this.setState({
        all: data?.data?.all || {},
        by: data?.data?.by || [],
        isLoading: false,
      })
      this.makeDataSet(data.data?.by || [])
    } catch (error) {
      debugger
    }
  }

  makeDataSet = (data = []) => {
    let temp = data?.map((obj) => {
      return {
        title: obj?.vidhanSabha,
        color: `#${Math.floor(Math.random() * 16777215).toString(16)}`,
        value: parseInt(obj?.vote),
      }
    })
    this.setState({ dataSet: temp })
  }

  fetchConstituency = async () => {
    await api
      .getConstituency({
        type: 'Vidhan Sabha',
        limit: '10000',
        offset: '0',
      })
      .then((response) => {
        if (response.data.error) {
          toast.error(
            'No Vidhan Sabha found. Please make Vidhan Sabha first.',
            {
              autoClose: 1250,
              closeButton: false,
            }
          )
        } else {
          let temp = []

          const { data } = response

          // eslint-disable-next-line no-unused-expressions
          data?.data?.rows.forEach((obj) => {
            temp.push({
              label: obj?.meta?.name,
              value: obj?.meta.name,
            })
          })

          this.setState({ vidhanSabhaOptions: [...temp] })
        }
      })
    await api
      .getConstituency({
        type: 'Gram Panchayat',
        limit: '10000',
        offset: '0',
      })
      .then((response) => {
        if (response.data.error) {
          toast.error('No Panchayat found. Please make Panchayat first.', {
            autoClose: 1250,
            closeButton: false,
          })
        } else {
          let temp = []

          const { data } = response

          // eslint-disable-next-line no-unused-expressions
          data?.data?.rows.forEach((obj) => {
            temp.push({
              label: obj?.meta?.name,
              value: obj?.meta.name,
            })
          })

          this.setState({ panchayatOptions: [...temp] })
        }
      })
    await api
      .getConstituency({
        type: 'Polling Station',
        limit: '10000',
        offset: '0',
      })
      .then((response) => {
        if (response.data.error) {
          toast.error(
            'No Polling Station found. Please make Polling Station first.',
            {
              autoClose: 1250,
              closeButton: false,
            }
          )
        } else {
          let temp = []

          const { data } = response

          // eslint-disable-next-line no-unused-expressions
          data?.data?.rows.forEach((obj) => {
            temp.push({
              label: obj?.meta?.name,
              value: obj?.meta.name,
            })
          })

          this.setState({ pollingStationOptions: [...temp] })
        }
      })
  }

  resetHandler = () => {
    this.setState(
      {
        vidhanSabha: '',
        pollingStation: '',
        year: '',
        panchayat: '',
      },
      () => this.fetchReport()
    )
  }

  render() {
    return (
      <div className="BackgroundHomeframe">
        <Header isLoading={this.state.isLoading} />
        <div className="frame">
          <div className="FormOuterFrame">
            <div className="DivHeading">
              <p className="ConstituencyHead mb-0">Analytics</p>
            </div>
            <div className="constituencyTabs">
              <div className="row m-0 mb-3" style={{ paddingLeft: '2rem' }}>
                <button
                  type="button"
                  className="border rounded-sm bg-white px-2 py-1"
                  style={{
                    color: '#2f2d64',
                    fontFamily: 'SegoeBold',
                    fontSize: '14px',
                  }}
                  onClick={(e) => {
                    e.preventDefault()
                    this.resetHandler()
                  }}
                >
                  Reset
                </button>
              </div>
              <div className="row m-0 p-0">
                <div className="col-12 col-md-9 col-lg-10">
                  <div className="row m-0 p-0">
                    <div className="col-12 col-md-6 col-lg-4">
                      <DatePicker
                        dateFormat="yyyy"
                        required
                        placeholder="Select Year"
                        className="InputFrame"
                        style={{
                          margin: '0',
                        }}
                        selected={this.state.year}
                        onChange={(date) => {
                          this.setState({ year: date }, () =>
                            this.fetchReport()
                          )
                        }}
                      />
                    </div>
                    <div className="col-12 col-md-6 col-lg-4">
                      <Typeahead
                        id="vidhanSabha"
                        labelKey="label"
                        placeholder="Vidhan Sabha"
                        required
                        onChange={(vidhanSabha) => {
                          this.setState(
                            {
                              vidhanSabha: vidhanSabha[0]?.value || '',
                            },
                            () => this.fetchReport()
                          )
                        }}
                        options={this?.state?.vidhanSabhaOptions}
                        defaultInputValue={this.state.vidhanSabha}
                      />
                    </div>
                    {/* <div className="col-12 col-md-6 col-lg-3">
                      <Typeahead
                        id="gramPanchayat"
                        labelKey="label"
                        placeholder="Gram Panchayat"
                        required
                        onChange={(panchayat) => {
                          this.setState(
                            {
                              panchayat: panchayat[0]?.value || '',
                            },
                            () => this.fetchReport()
                          )
                        }}
                        options={this?.state?.panchayatOptions}
                        defaultInputValue={this.state.panchayat}
                      />
                    </div> */}
                    <div className="col-12 col-md-6 col-lg-4">
                      <Typeahead
                        id="vidhanSabha"
                        labelKey="label"
                        placeholder="Polling Station"
                        required
                        onChange={(pollingStation) => {
                          this.setState(
                            {
                              pollingStation: pollingStation[0]?.value || '',
                            },
                            () => this.fetchReport()
                          )
                        }}
                        options={this?.state?.pollingStationOptions}
                        defaultInputValue={this.state.pollingStation}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-3 col-lg-2">
                  <button
                    type="button"
                    className="text-white rounded-sm"
                    style={{
                      backgroundColor: '#2f2d64',
                      fontFamily: 'Segoe UI',
                      border: '0',
                      fontSize: '14px',
                      width: '100%',
                      padding: '11px 0',
                    }}
                    onClick={(e) => {
                      e.preventDefault()
                      this.fetchReport()
                    }}
                  >
                    Apply
                  </button>
                </div>
              </div>
            </div>
            <div className="FormFrame pb-4">
              <div className="row p-0 m-0">
                <div className="col-12 col-md-6">
                  <h5
                    className="fs-4 txt-color-primary text-left mb-1"
                    style={{
                      fontFamily: 'SegoeBold',
                    }}
                  >
                    Total Votes
                  </h5>
                  <p
                    className="fs-6 txt-color-primary text-left mb-3"
                    style={{
                      fontFamily: 'SegoeBold',
                    }}
                  >
                    {this.state?.all?.total || '---'}
                  </p>
                  <div
                    className="bg-white px-3 py-3 border rounded-lg"
                    style={{
                      borderColor: '1px solid rgba(0,0,0,0.1)',
                    }}
                  >
                    <PieChart
                      paddingAngle={2}
                      style={{
                        width: '70%',
                        height: 'auto',
                        marginBottom: '2rem',
                      }}
                      data={this.state.dataSet}
                    />
                    {this.state?.dataSet && this.state.dataSet.length !== 0
                      ? this.state.dataSet.map((data) => {
                          return (
                            <div className="d-flex align-items-center mb-1">
                              <p
                                className=" txt-color-primary text-left mb-0 fw-semibold"
                                style={{
                                  fontFamily: 'Segoe UI',
                                  fontSize: '14px',
                                }}
                              >
                                <span
                                  className="mr-2 d-inline-block"
                                  style={{
                                    backgroundColor: data?.color,
                                    borderRadius: '50px',
                                    width: '10px',
                                    height: '10px',
                                  }}
                                ></span>
                                {data?.title || ''}
                              </p>
                              <p
                                className="txt-color-primary text-left mb-0 fw-semibold ml-5 "
                                style={{
                                  fontFamily: 'Segoe UI',
                                  fontSize: '14px',
                                }}
                              >
                                {data?.value || ''}
                              </p>
                            </div>
                          )
                        })
                      : null}
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <h5
                    className="fs-4 mb-1 txt-color-primary text-left"
                    style={{
                      fontFamily: 'SegoeBold',
                    }}
                  >
                    Total BJP Votes
                  </h5>
                  <p
                    className="fs-6 txt-color-primary text-left mb-3"
                    style={{
                      fontFamily: 'SegoeBold',
                    }}
                  >
                    {this.state?.all?.vote || '---'}
                  </p>
                  <div
                    className="bg-white px-3 py-3 border rounded-lg"
                    style={{
                      borderColor: '1px solid rgba(0,0,0,0.1)',
                    }}
                  >
                    <PieChart
                      paddingAngle={2}
                      style={{
                        width: '70%',
                        height: 'auto',
                        marginBottom: '2rem',
                      }}
                      data={[
                        {
                          title: 'Total Votes',
                          color: '#4f9fff',
                          value: parseInt(this.state?.all?.total || 0),
                        },
                        {
                          title: 'Total BJP Votes',
                          color: '#0662d0',
                          value: parseInt(this.state?.all?.vote || 0),
                        },
                      ]}
                    />
                    <div className="d-flex align-items-center mb-1">
                      <p
                        className=" txt-color-primary text-left mb-0 fw-semibold"
                        style={{
                          fontFamily: 'Segoe UI',
                          fontSize: '14px',
                        }}
                      >
                        <span
                          className="mr-2 d-inline-block"
                          style={{
                            backgroundColor: '#4f9fff',
                            borderRadius: '50px',
                            width: '10px',
                            height: '10px',
                          }}
                        ></span>
                        Total Votes
                      </p>
                      <p
                        className="txt-color-primary text-left mb-0 fw-semibold ml-5 "
                        style={{
                          fontFamily: 'Segoe UI',
                          fontSize: '14px',
                        }}
                      >
                        {this.state?.all?.total || '---'}
                      </p>
                    </div>
                    <div className="d-flex align-items-center mb-1">
                      <p
                        className=" txt-color-primary text-left mb-0 fw-semibold"
                        style={{
                          fontFamily: 'Segoe UI',
                          fontSize: '14px',
                        }}
                      >
                        <span
                          className="mr-2 d-inline-block"
                          style={{
                            backgroundColor: '#0662d0',
                            borderRadius: '50px',
                            width: '10px',
                            height: '10px',
                          }}
                        ></span>
                        Total BJP Votes
                      </p>
                      <p
                        className="txt-color-primary text-left mb-0 fw-semibold ml-5 "
                        style={{
                          fontFamily: 'Segoe UI',
                          fontSize: '14px',
                        }}
                      >
                        {this.state?.all?.vote || '---'}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="DashboardFooter">
            <Footer />
            <CopyrightFooter />
          </div>
        </div>
        <div className="emptyDiv"></div>
      </div>
    )
  }
}
