import React, { Component } from 'react'
import downloadBtn from '../../../utils/images/downloadBtn.png'

class Videos extends Component {
  render() {
    return (
      <div>
        
      </div>
    )
  }
}

export default Videos
