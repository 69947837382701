import React, { Component } from 'react'
import {
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableHead,
  Paper,
  Collapse,
  Box,
  IconButton,
} from '@material-ui/core'
import { toast } from 'react-toastify'
import TablePagination from '@material-ui/core/TablePagination'

import api from '../../utils/api'
import Header from '../header/Header'
import Plus from '../../utils/images/plus.svg'
import Trash from '../../utils/images/trash.svg'
import { Link } from 'react-router-dom/cjs/react-router-dom.min'
import { Fragment } from 'react'
import Warning from '../hoc/Warning/Warning'
import moment from 'moment/moment'
import Footer from '../footer/Footer'
import CopyRightFooter from '../../hoc/footers/CopyRightFooter'
import Loader from '../hoc/Loader/Loader'

import Back from '../../utils/images/back.svg'
import { Autocomplete } from '@material-ui/lab'
import { TextField } from '@material-ui/core'
import { useState } from 'react'
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons'

class ManageElectionsData extends Component {
  constructor(props) {
    super(props)

    document.title = 'Manage Elections'
    const { role, byUser, orgId } = JSON.parse(
      localStorage.getItem('eSahyogiUser')
    ).data

    this.state = {
      electionsData: {},
      mountComponent: false,
      isLoading: true,
      page: 0,
      rowsPerPage: 25,
      showModal: false,
      export: '',
      role: role,
      byUser: { ...byUser },
      orgId: orgId,
      district: '',
      vidhanSabha: '',
      pollingStation: '',
      districtOption: [],
      vidhanSabhaOption: [],
      pollingStationOptions: [],
      finalData: [],
    }
  }

  setPage = (event, newPage) => {
    this.setState({ page: newPage })
  }

  setRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value, page: 0 })
  }

  filteredData = {
    data: {
      rows: [],
    },
  }

  componentDidMount() {
    const role = JSON.parse(localStorage.getItem('eSahyogiUser')).data.role
    this.fetchElectionsData()
    this.fetchConstituency()
  }

  fetchElectionsData = () => {
    this.setState({ isLoading: true })
    api
      .getAllElectionData({
        vidhanSabha: this.state.vidhanSabha,
        district: this.state.district,
        pollingStation: this.state.pollingStation,
      })
      .then((response) => {
        if (response.ok) {
          if (response.data.error) {
            this.setState({ showWarning: true, isLoading: false })
          } else {
            this.setState(
              {
                electionsData: response.data.data,
                isLoading: false,
                showWarning: false,
              },
              () => {
                this.groupedData()
              }
            )
          }
        } else {
          toast.error('Something went wrong', {
            autoClose: 1250,
            closeButton: false,
          })
        }
      })
      .catch((err) => {
        console.log(this.state)
      })
  }

  groupedData = () => {
    let data = this.state.electionsData?.rows?.reduce((acc, current) => {
      const key = `${current.district}-${current.vidhanSabha}-${
        current?.meta?.village
      }-${current?.pollingStation}-${current.meta?.electionType || ''}`

      // Check if the key already exists in the accumulator
      const existingEntry = acc.find((entry) => entry.key === key)

      // If the key exists, add the current data to its 'data' array
      if (existingEntry) {
        existingEntry.data.push({
          total: current.total,
          vote: current.vote,
          year: current.year,
          percent: current.percent,
          id: current.id,
        })
      } else {
        // If the key doesn't exist, create a new entry
        acc.push({
          district: current.district,
          vidhanSabha: current.vidhanSabha,
          village: current?.meta?.village,
          pollingStation: current.pollingStation,
          electionType: current?.meta?.electionType || '',
          createdAt: current.createdAt,
          key: key,
          data: [
            {
              total: current.total,
              vote: current.vote,
              year: current.year,
              percent: current.percent,
              id: current.id,
            },
          ],
        })
      }

      return acc
    }, [])

    this.setState({
      finalData: [...data],
    })
  }

  updateElectionsData = (rowsData) => {
    this.props.history.push({
      pathname: '/update-election-data',
      state: {
        ...rowsData,
        isUpdate: true,
      },
    })
  }

  deleteElectionData = (id) => {
    api
      .deleteElectionData({ id: id })
      .then((response) => {
        if (response.ok) {
          toast.success('Entry Deleted SuccessFully', {
            autoClose: 1250,
            closeButton: false,
          })
          this.fetchElectionsData()
        } else {
          toast.error('Something Went Wrong.', {
            autoClose: 1500,
            closeButton: false,
          })
        }
      })
      .catch((err) => {
        toast.error('Something Went Wrong. Please Refresh', {
          autoClose: false,
          closeButton: false,
        })
      })
  }

  afterImport = (data) => {
    const callingDirectories = []

    data.map((obj, index) => {
      let newData = new Object()
      let meta = {}

      meta.callerMeet = obj.callerMeet
      meta.gramPanchayat = obj.gramPanchayat
      meta.vidhanSabha = obj.vidhanSabha
      meta.block = obj.block
      meta.address = obj.address
      meta.constituency = obj.constituency
      meta.state = obj.state
      meta.pinCode = obj.pinCode

      delete obj.callerMeet
      delete obj.gramPanchayat
      delete obj.vidhanSabha
      delete obj.block
      delete obj.address
      delete obj.constituency
      delete obj.state
      delete obj.pinCode

      Object.assign(newData, obj)
      newData.meta = { ...meta }
      newData.byUser = { ...this.state.byUser }
      newData.orgId = this.state.orgId
      callingDirectories.push(newData)
    })
    if (callingDirectories || callingDirectories.length !== 0) {
      const reqData = { callingDirectories }

      api
        .bulkImportCalling(reqData)
        .then((response) => {
          if (response.data.error) {
            toast.error('Unable to upload.', {
              autoClose: 1250,
              closeButton: false,
            })
          } else {
            toast.success('Upload successful', {
              autoClose: 1250,
              closeButton: false,
            })
            setTimeout(() => window.location.reload(true), 500)
          }
        })
        .catch((err) => {
          toast.error('Something Went Wrong. Try Again', {
            autoClose: 1250,
            closeButton: false,
          })
        })
    }
  }

  //   onImportExcel = (file) => {
  //     const { files } = file.target
  //     let data = []

  //     // Read the file through the FileReader object
  //     const fileReader = new FileReader()

  //     // Open the file in binary mode
  //     fileReader.readAsBinaryString(files[0])

  //     fileReader.onload = (event) => {
  //       try {
  //         const { result } = event.target

  //         // Read the entire excel table object in binary stream

  //         const workbook = XLSX.read(result, { type: 'binary' })

  //         for (const sheet in workbook.Sheets) {
  //           if (workbook.Sheets.hasOwnProperty(sheet)) {
  //             data = data.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]))
  //           }
  //         }

  //         if (data.length !== 0) {
  //           this.afterImport(data)
  //         }

  //         // Finally obtained and formatted json data
  //         toast.success('Successfully Uploaded', {
  //           autoClose: 1250,
  //           closeButton: false,
  //         })
  //       } catch (e) {
  //         console.log('went worng', e)
  //       }
  //     }
  //   }

  //   exportData = () => {
  //     let csvData = this.state.callingDirectoryData.rows.map((data) => {
  //       return {
  //         Name: data.name,
  //         'Mobile Number': data.mobileNumber,
  //         Address: data.meta.address,
  //         State: data.meta.state,
  //         'Caller From': data.callerFrom,
  //         Constituency: data.meta.constituency,
  //         'Gram Panchayat': data.meta.gramPanchayat,
  //         'Vidhan Sabha': data.meta.vidhanSabha,
  //         Block: data.meta.block,
  //         Pincode: data.meta.pinCode,
  //         Purpose: data.purpose,
  //         'Want To ?': data.meta.callerMeet,
  //       }
  //     })

  //     let date = new Date()

  //     const fileType =
  //       'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  //     const fileExtension = '.xlsx'

  //     const ws = XLSX.utils.json_to_sheet(csvData)
  //     const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
  //     const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
  //     const data = new Blob([excelBuffer], { type: fileType })
  //     FileSaver.saveAs(
  //       data,
  //       'Calling_Directory_data' +
  //         `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}` +
  //         fileExtension
  //     )
  //   }

  fetchConstituency = () => {
    api
      .getConstituency({
        type: 'Zila Level',
        limit: '10000',
        offset: '0',
      })
      .then((response) => {
        if (response.data.error) {
          toast.error('No Disrict found. Please make Disrict first.', {
            autoClose: 1250,
            closeButton: false,
          })
        } else {
          toast.success('District Fetched sucessfully.', {
            autoClose: 1250,
            closeButton: false,
          })
          localStorage.setItem(
            'District',
            JSON.stringify(response.data.data.rows)
          )
          this.makeDistrict()
        }
      })
    api
      .getConstituency({
        type: 'Vidhan Sabha',
        limit: '10000',
        offset: '0',
      })
      .then((response) => {
        if (response.data.error) {
          toast.error(
            'No Vidhan Sabha found. Please make Vidhan Sabha first.',
            {
              autoClose: 1250,
              closeButton: false,
            }
          )
        } else {
          toast.success('Vidhan Sabha Fetched sucessfully.', {
            autoClose: 1250,
            closeButton: false,
          })
          localStorage.setItem(
            'Vidhan Sabha',
            JSON.stringify(response.data.data.rows)
          )
          this.makeVidhanSabha()
        }
      })
    api
      .getConstituency({
        type: 'Polling Station',
        limit: '10000',
        offset: '0',
      })
      .then((response) => {
        if (response.data.error) {
          toast.error(
            'No Polling Station found. Please make Polling Station first.',
            {
              autoClose: 1250,
              closeButton: false,
            }
          )
        } else {
          toast.success('Polling Station Fetched sucessfully.', {
            autoClose: 1250,
            closeButton: false,
          })
          localStorage.setItem(
            'Polling Station',
            JSON.stringify(response.data.data.rows)
          )
          this.makePollingStation()
        }
      })
  }

  makeDistrict = () => {
    const district = JSON.parse(localStorage.getItem('District'))
    let temp = []
    district.map((data) => {
      temp.push(data.meta.name)
    })
    this.setState({
      districtOption: [...temp],
    })
  }

  makeVidhanSabha = () => {
    const vidhanSabha = JSON.parse(localStorage.getItem('Vidhan Sabha'))
    let temp = []
    vidhanSabha.map((data) => {
      if (
        data.meta.district === this.state.district ||
        this.state.district === ''
      ) {
        temp.push(data.meta.name)
      }
    })
    if (temp.length === 0) {
      toast.error(
        'No Vidhan Sabha for this district. Please make Vidhan sabha.',
        {
          autoClose: 1500,
          closeButton: false,
        }
      )
    } else {
      this.setState({
        vidhanSabhaOption: [...temp],
      })
    }
  }

  makePollingStation = () => {
    const Panchayat = JSON.parse(localStorage.getItem('Polling Station'))
    let temp = []
    Panchayat.map((data) => {
      if (
        data?.meta?.vidhanSabha === this.state.vidhanSabha ||
        this.state.vidhanSabha === ''
      ) {
        temp.push(data.meta.name)
      }
    })
    if (temp.length === 0) {
      toast.error(
        'No Polling Station for this vidhan sabha. Please make Polling Station.',
        {
          autoClose: 1500,
          closeButton: false,
        }
      )
    } else {
      this.setState({
        pollingStationOptions: [...temp],
      })
    }
  }

  render() {
    return (
      <div className="BackgroundHomeframe">
        <Header isLoading={this.state.isLoading} />
        <div className="frame2" style={{ marginTop: '100px' }}>
          <div className="FormOuterFrame">
            <div
              className="DivHeading"
              style={{ justifyContent: 'center', marginTop: '-17px' }}
            >
              <p className="ConstituencyHead" style={{ marginLeft: '35%' }}>
                Manage Elections
              </p>
              <Link style={{ marginLeft: '26%' }} to={'/add-election-data'}>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <p className="UpperTabTxt" style={{ marginRight: '10px' }}>
                    Add
                  </p>
                  <img src={Plus} alt="" />
                </div>
              </Link>
            </div>
            <div className="constituencyTabs visitor">
              {/* <div className="filter-div">
                <div className="filter" onClick={() => this.showFilter()}>
                  <p>{this.state.caller}</p>
                  <span>{filterIcon}</span>
                </div>
                <ul id="filter-options" className="filter-options">
                  <li
                    onClick={() => {
                      this.setState(
                        {
                          caller: 'ALL',
                          district: '',
                          vidhanSabha: '',
                          panchayat: '',
                          districtOption: [],
                          vidhanSabhaOption: [],
                          panchayatOption: [],
                        },
                        () => {
                          this.hideFilter()
                          this.fetchConstituency()
                          setTimeout(() => {
                            this.fetchFilterData()
                          }, 1500)

                          this.fetchCaller()
                        }
                      )
                    }}
                  >
                    ALL
                  </li>
                  <li
                    onClick={() => {
                      this.setState(
                        {
                          caller: 'CONSTITUENCY',
                          district: '',
                          vidhanSabha: '',
                          panchayat: '',
                          districtOption: [],
                          vidhanSabhaOption: [],
                          panchayatOption: [],
                        },
                        () => {
                          this.hideFilter()
                          this.fetchConstituency()
                          this.fetchCaller()
                        }
                      )
                    }}
                  >
                    CONSTITUENCY
                  </li>
                  <li
                    onClick={() => {
                      this.setState(
                        {
                          caller: 'OTHER',
                          district: '',
                          vidhanSabha: '',
                          panchayat: '',
                          districtOption: [],
                          vidhanSabhaOption: [],
                          panchayatOption: [],
                        },
                        () => {
                          this.hideFilter()
                          this.fetchFilterData()
                          this.fetchCaller()
                        }
                      )
                    }}
                  >
                    OTHER
                  </li>
                </ul>
              </div> */}
              {/* <div className="SearchDiv visitor">
                <div className="DateSearch">
                  <div
                    onClick={() => this.setState({ showCalender: true })}
                    style={{ borderRight: '1px solid #E3E3E8' }}
                  >
                    <img className="calender" src={DateIcon} alt=""></img>
                  </div>
                </div>
                <div className="SearchDivLayoutCallingDirectory">
                  <input
                    type="text"
                    placeholder="Search Requests (Name, Mobile Number)"
                    className="SearchInput"
                    value={this.state.search}
                    onChange={(e) => {
                      if (this.state.isDateFilterOn) {
                        this.setState({ search: e.target.value }, () =>
                          this.fetchCallingDirectoryWithDate()
                        )
                      } else {
                        this.setState({ search: e.target.value }, () =>
                          this.fetchCaller()
                        )
                      }
                    }}
                  />
                  <img src={Search} alt="" className="SearchIcon" />
                </div>
                <button
                  type="button"
                  className="resetBtn"
                  onClick={() => this.resetHandler()}
                >
                  Reset
                </button>
                <div id="imprtExport" className="importExportSec">
                  <div class="importExportBtn">
                    <label className="importLabel">Import</label>
                    <input
                      className="importBtn"
                      type="file"
                      accept=".xlsx, .xls"
                      onChange={this.onImportExcel}
                    />
                    {svg.importGrey}
                  </div>
                  <div
                    class="importExportBtn export"
                    onClick={() => this.exportData()}
                  >
                    <p>Export</p>
                    {svg.importGrey}
                  </div>
                </div>
              </div> */}
              <div className="filter-section">
                <Autocomplete
                  id="combo-box-demo"
                  className="filter-box"
                  options={this.state.districtOption}
                  getOptionLabel={(option) => {
                    console.log('option', option)
                    return option
                  }}
                  value={this.state.district}
                  sx={{ width: 500 }}
                  onChange={(event, value) => {
                    if (value !== null && value !== undefined) {
                      this.setState({ district: value }, () => {
                        this.fetchElectionsData()
                      })
                    } else {
                      this.setState(
                        {
                          district: '',
                        },
                        () => {
                          this.fetchElectionsData()
                        }
                      )
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="District" />
                  )}
                />
                <Autocomplete
                  id="combo-box-demo"
                  className="filter-box"
                  options={this.state.vidhanSabhaOption}
                  getOptionLabel={(option) => option}
                  value={this.state.vidhanSabha}
                  sx={{ width: 500 }}
                  onChange={(event, value) => {
                    if (value !== null && value !== undefined) {
                      this.setState({ vidhanSabha: value }, () => {
                        this.fetchElectionsData()
                      })
                    } else {
                      this.setState(
                        {
                          vidhanSabha: '',
                        },
                        () => {
                          this.fetchElectionsData()
                        }
                      )
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Vidhan Sabha" />
                  )}
                />
                <Autocomplete
                  id="combo-box-demo"
                  className="filter-box"
                  options={this.state.pollingStationOptions}
                  getOptionLabel={(option) => option}
                  value={this.state.pollingStation}
                  sx={{ width: 500 }}
                  onChange={(event, value) => {
                    if (value !== null && value !== undefined) {
                      this.setState({ pollingStation: value }, () =>
                        this.fetchElectionsData()
                      )
                    } else {
                      this.setState({ pollingStation: '' }, () =>
                        this.fetchElectionsData()
                      )
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} label="Polling Station" />
                  )}
                />
              </div>
            </div>
            <div className="FormFrame">
              {this.state.isLoading ? null : this.state.showWarning ? (
                <Warning warningMsg="No Data Found" />
              ) : (
                <Fragment>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{
                            borderTop: 'none',
                            width: '9%',
                          }}
                        ></TableCell>
                        <TableCell
                          style={{
                            borderTop: 'none',
                            width: '9%',
                          }}
                        >
                          <p className="HeadingTxt">Date</p>
                        </TableCell>
                        <TableCell
                          style={{
                            borderTop: 'none',
                          }}
                        >
                          <p className="HeadingTxt">Election Type</p>
                        </TableCell>
                        <TableCell style={{ borderTop: 'none' }}>
                          <p className="HeadingTxt">District</p>
                        </TableCell>
                        <TableCell style={{ borderTop: 'none' }}>
                          <p className="HeadingTxt1">Vidhan Sabha</p>
                        </TableCell>
                        <TableCell style={{ borderTop: 'none' }}>
                          <p className="HeadingTxt">Village</p>
                        </TableCell>
                        <TableCell style={{ borderTop: 'none' }}>
                          <p className="HeadingTxt">Polling Station</p>
                        </TableCell>

                        <TableCell style={{ borderTop: 'none' }}></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.finalData
                        ?.slice(
                          this.state.page * this.state.rowsPerPage,
                          this.state.page * this.state.rowsPerPage +
                            this.state.rowsPerPage
                        )
                        .map((data, index) => {
                          console.log('data', data)
                          return (
                            <TableCollapseRow
                              rowData={data}
                              deleteElectionData={this.deleteElectionData}
                              updateElectionsData={this.updateElectionsData}
                              key={index}
                              role={this.state.role}
                            />
                            // <TableRow>
                            //   <TableCell>
                            //     <p className="DataTxt">
                            //       {moment(data.createdAt)
                            //         .utc()
                            //         .startOf('day')
                            //         .format('DD/MM/YYYY')}
                            //     </p>
                            //   </TableCell>
                            //   <TableCell>
                            //     <div
                            //       style={{
                            //         alignSelf: 'center',
                            //       }}
                            //     >
                            //       <p className="DataTxt">
                            //         {data.meta?.electionType || ''}
                            //       </p>
                            //     </div>
                            //   </TableCell>
                            //   <TableCell>
                            //     <p className="DataTxt">{data?.district} </p>
                            //   </TableCell>
                            //   <TableCell>
                            //     <p className="DataTxt">{data?.vidhanSabha}</p>
                            //   </TableCell>
                            //   <TableCell>
                            //     <p className="DataTxt">{data?.panchayat}</p>
                            //   </TableCell>
                            //   <TableCell>
                            //     <p className="DataTxt">
                            //       {data?.pollingStation}
                            //     </p>
                            //   </TableCell>
                            //   <TableCell>
                            //     <p className="DataTxt">{data?.year}</p>
                            //   </TableCell>
                            //   <TableCell>
                            //     <p className="DataTxt">{data?.total}</p>
                            //   </TableCell>
                            //   <TableCell>
                            //     <p className="DataTxt">{data?.vote}</p>
                            //   </TableCell>

                            // </TableRow>
                          )
                        })}
                    </TableBody>
                  </Table>
                  <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    component="div"
                    count={this.state.finalData.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onChangePage={this.setPage}
                    onChangeRowsPerPage={this.setRowsPerPage}
                  />
                </Fragment>
              )}
            </div>
          </div>
          <div className="DashboardFooter">
            <Footer />
            <CopyRightFooter />
          </div>
        </div>
        {this.state.isLoading ? <Loader /> : null}
        {/* {this.state.showCalender ? (
          <div className="Modal">
            <div className="modalContainer">
              <DateRangePicker
                onChange={(item) =>
                  this.setState({ rangeDate: item.selection })
                }
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={[this.state.rangeDate]}
                direction="horizontal"
              />
              <div className="datePickerBtns">
                <button
                  type="button"
                  className="resetBtn"
                  onClick={() => {
                    this.setState({ showCalender: false })
                  }}
                  style={{ margin: '0 10px' }}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="resetBtn"
                  style={{ margin: '0 10px' }}
                  onClick={(e) =>
                    this.setState(
                      { showCalender: false, isDateFilterOn: true },
                      () => this.fetchCallingDirectoryWithDate()
                    )
                  }
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        ) : null} */}
        <div className="emptyDiv"></div>
      </div>
    )
  }
}

const TableCollapseRow = ({
  rowData,
  deleteElectionData,
  updateElectionsData,
  role,
}) => {
  const [open, setOpen] = useState(false)

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <p className="DataTxt">
            {moment(rowData.createdAt)
              .utc()
              .startOf('day')
              .format('DD/MM/YYYY')}
          </p>
        </TableCell>
        <TableCell>
          <p className="DataTxt">{rowData?.electionType}</p>
        </TableCell>
        <TableCell align="right">
          <p className="DataTxt">{rowData?.district}</p>
        </TableCell>
        <TableCell align="right">
          {' '}
          <p className="DataTxt">{rowData?.vidhanSabha}</p>
        </TableCell>
        <TableCell align="right">
          {' '}
          <p className="DataTxt">{rowData?.village}</p>
        </TableCell>
        <TableCell align="right">
          {' '}
          <p className="DataTxt">{rowData?.pollingStation}</p>
        </TableCell>
        <TableCell onClick={() => updateElectionsData(rowData)}>
          <img src={Back} alt="" className="Back" />
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: '0', paddingTop: '0' }} colSpan={12}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <p
                className="HeadingTxt"
                style={{ fontSize: '1.25rem', margin: '1rem 0 1.5rem' }}
              >
                Report
              </p>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <p className="HeadingTxt">Year</p>
                    </TableCell>

                    <TableCell>
                      <p className="HeadingTxt">Total Votes</p>
                    </TableCell>
                    <TableCell>
                      <p className="HeadingTxt">BJP Votes</p>
                    </TableCell>
                    <TableCell>
                      <p className="HeadingTxt">Percentage</p>
                    </TableCell>
                    <TableCell>
                      <p className="HeadingTxt"></p>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rowData?.data.map((dataRow) => (
                    <TableRow key={dataRow?.id}>
                      <TableCell>
                        <p className="DataTxt">{dataRow?.year}</p>
                      </TableCell>

                      <TableCell>
                        <p className="DataTxt">{dataRow?.total}</p>
                      </TableCell>
                      <TableCell align="right">
                        <p className="DataTxt">{dataRow?.vote}</p>
                      </TableCell>
                      <TableCell align="right">
                        <p className="DataTxt">{dataRow?.percent}</p>
                      </TableCell>
                      <TableCell>
                        {role === 'USER' ? null : (
                          <img
                            src={Trash}
                            alt=""
                            className="deleteIcon"
                            style={{ cursor: 'pointer' }}
                            onClick={() => deleteElectionData(dataRow.id)}
                          ></img>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  )
}

export default ManageElectionsData
