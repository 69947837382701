import React from 'react'

const ErrorComponent = (props) => {
  return (
    <span
      className="validation-error-message"
      style={{
        margin: '0',
        position: 'absolute',
        bottom: '0',
      }}
    >
      {props.children}
    </span>
  )
}

export default ErrorComponent
